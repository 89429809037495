import React from 'react';

// Context Files
import { ThemeMode } from '../../context/ThemeMode';

// Components 
import Logo from '../../components/Logo';
import Switche from '../../components/commonfield/Switche';

// Icons & Img
import Icons from '../../components/icons';
import loginBg from '../../img/login-bg.webp';

export default function LayoutSignInUp({ children }) {

  // --- ---
  const { isDarkMode, toggleTheme } = ThemeMode();
  // --- ---

  return (
    <>
      <div className="pattern-bx pb-full">

      </div>

      <section className="signinup-main-box scrollbarHidden">
        <img className='smb-side-img' src={loginBg} alt="" />
        <div className="smb-content-box">
          <div className='smb-content-wraper'>
            <div className="signinup-header">
              <Logo />
              <div className="cr-theme-btn ctb-sch-btn d-none">
                <div className="cr-tb-lable">{isDarkMode ? "Dark mode" : "Light mode"}</div>
                <Switche
                  onClick={toggleTheme} 
                  className={isDarkMode ? "active" : ""}
                  icon={isDarkMode ?  <Icons.MoonSvg /> : <Icons.SunSvg />}
                />
              </div>
            </div>
            {children}
          </div>
        </div>
      </section>
    </>
    
  );
}
