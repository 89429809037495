import React from 'react'
import { Link } from 'react-router-dom';

// import logo from "../img/logo/logo.png"
import logoIcon from "../img/logo/icon.png"
import logoText from "../img/logo/text-logo.png"

export default function Logo({className, to="/"}) {
  return (
    <Link to={to} className={`logo-bx ${className}`}>
        {/* <img className="logo-img" src={logo} alt="" /> */}
        <img className="logo-icon" src={logoIcon} alt="" />
        <img className="logo-text" src={logoText} alt="" />
    </Link>
  )
}
