import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate } from 'react-router-dom';
import FlashMessage from '../../components/FlashMessage';

import LayoutSignInUp from './LayoutSignInUp';

// Common Field
import PasswordInput from '../../components/commonfield/PasswordInput'

// Images Common File
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

export default function PageReset() {
  const { apiCall } = useApi();
  const { token } = useParams();
  
  const navigate = useNavigate();
 const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = (value) => {
    setPassword(value);
    setErrors((prev) => ({ ...prev, password: '' })); 
  }; 

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setErrors((prev) => ({ ...prev, confirm_password: '' })); 
  };

  const validateFieldsStep = () => {
    const newErrors = {};
    if (!password) {
      newErrors.password = 'Password is required';
    } else if(password && password.length < 6){
      newErrors.password = 'Minimum 6 characters required';
    }
    if (!confirmPassword) {
      newErrors.confirm_password = 'Confirm password is required';
    } else if(confirmPassword.length < 6){
      newErrors.confirm_password = 'Minimum 6 characters required';
    } else if(password && confirmPassword){
      if(password !== confirmPassword){
        newErrors.confirm_password = 'Password and confirm password must match';
      }
    }
   
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFieldsStep();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('password_confirmation',confirmPassword);
      formData.append('password',password);
      formData.append('token',token);
      
      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'reset-forgot-password', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success === 0){
              setPassword('');
              setConfirmPassword('');
              setRequestSubmitSuccsess(true)

              setTimeout(() => {
                navigate('/');
              }, 2000);
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };

  return (
    <>
    <LayoutSignInUp>
      <div className="signinup-screen">

          <div className="screen-header">
            <div className="screen-hed">Reset a Password</div>
            <div className="screen-pera">Please enter your email to help change your password.</div>
          </div>

          <form className="signinup-width">
            <div className="row">

              <div className="col-12">
                  <PasswordInput heading="New Password" className="sgl-icon" sgl={<Icons.LockSvg/>} type="password" placeholder="Enter new password" errorMeg={errors?.password} error={errors?.password} value={password} onChange={handlePasswordChange} />
              </div>

              <div className="col-12">
                  <PasswordInput heading="Confirm password" className="sgl-icon" sgl={<Icons.LockSvg/>} type="password" placeholder="Enter confirm password" errorMeg={errors?.confirm_password} error={errors?.confirm_password} value={confirmPassword} onChange={handleConfirmPasswordChange} />
              </div>

              <div className="col-12">
                {!isLoading && <div className="common-submit-btn csb-width-full" onClick={handleSubmit}>Update Password</div>
                }
                {isLoading && <div className="lsb-loader-btn csb-width-full">
                  <img src={Img.loading} alt="" />Please wait ....
                </div>
                }
              </div>

              <div className="col-12">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to 
                    <Link className="signinup-link-two" to="/">Sign in</Link>
                  </div>
              </div>

            </div>
          </form>
      </div>
    </LayoutSignInUp>
    <FlashMessage type="success" isVisible={requestSubmitSuccess} message="You have successfully logged in" />
    <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
