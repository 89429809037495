import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/AuthContext'

// Css Links
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/themecolor.css';
import './css/themecolor2.css';
import "./css/loader.css";
import "./css/header.css"
import "./css/sidemenu.css"
import './css/flashmessage.css';
import './css/style.css';
import './css/commonform.css';
import './css/signinup.css';
import './css/pattern.css';
// import './css/lighttheme.css';



import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
