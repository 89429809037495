import React from 'react'
import { Link } from 'react-router-dom'
import * as Img from './Img';

export default function UserInfoBx({ className="" }) {
  return (
    <Link to="/home" className={`user-info-bx ${className}`}>
        <div className="uib-img">
            <img src={Img.icon} alt="" />
        </div>
        <div className="uib-data">
            <div className="uib-name">Koray Okumus</div>
            <div className="uib-other">okumus@gmail.com</div>
        </div>
    </Link>
  )
}
