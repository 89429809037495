import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

// Context Files
import { useMenu } from '../../context/MenuContext';
import { ThemeMode } from '../../context/ThemeMode';

// Icons & imgeas common files
import Icons from '../../components/icons'

// Components
import Logo from '../../components/Logo';
import Sidemenu from './Sidemenu';
import Switche from '../../components/commonfield/Switche';
import UserInfoBx from '../../components/UserInfoBx';

export default function Header() {
  const [user, setUser] = useState('')
  const { state, dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  // --- ---
  const { isActive, toggleMenu, isMinActive, toggleMinMenu } = useMenu();
  // --- ---

  // --- ---
  const { isDarkMode, toggleTheme } = ThemeMode();
  // --- ---

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;
    if (isUserLoggedIn) {
      setUserLoggedIn(true);
      setUser(loggedInUser);
    } else {
      navigate('/');
    }
  }, [state.userData]);

  return (
    <>
      <header className={`custom-header ${isMinActive ? "cw-smb-mini" : "" }`}>
        <div className="ch-inner">
          <div className="ch-left">
            <div className="smb-min-btn" onClick={toggleMinMenu}><Icons.LeftSvg /></div>
            <div className={`menu-icon-bx ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Logo to="/home" className="ch-logo-bx" />
          </div>
          <div className="ch-right">

            <div className="cr-theme-btn d-none">
              <div className="cr-tb-lable">{isDarkMode ? "Dark mode" : "Light mode"}</div>
              <Switche
                onClick={toggleTheme}
                className={isDarkMode ? "active" : ""}
                icon={isDarkMode ? <Icons.MoonSvg /> : <Icons.SunSvg />}
              />
            </div>

            {/* <div onClick={toggleTheme} title={isDarkMode ? "Light mode" : "Dark mode"} className="cr-icons-link cr-theme-btn">
              <Icons.QuestionsSvg />
            </div> */}

            <div className="cr-icons-link cr-il-noti">
              <Icons.NotificationsSvg />
              <div className="cr-num"></div>
            </div>

            <UserInfoBx className="cr-uib" />

          </div>
        </div>
      </header>

      <Sidemenu />
    </>
  )
}
